import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux"

const BASE_URL = "https://my-json-server.typicode.com/themeland/gameon-json/footer";

class Footer extends Component {
    state = {
        data: {},
        socialData: [],
        widgetData: []
    }
    componentDidMount() {

    }
    render() {
        const socialStyle = { 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center', 'justifyContent': 'center' }
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 text-center">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/">
                                    {/* <img src={this.state.data.img} alt="" /> */}
                                </a>
                                {/* Social Icons */}
                                <div className="social-icons d-flex justify-content-center my-4">

                                    {this.props?.miningInfo?.website && <a style={socialStyle} className="facebook" href={this.props.miningInfo.website} target="_blank" rel="noreferrer">
                                        <i className="fas fa-globe"></i>
                                    </a>}
                                    {this.props?.miningInfo?.telagram && <a style={socialStyle} className="" href={this.props.miningInfo.telagram} target="_blank" rel="noreferrer">
                                        <i className='fab fa-telegram' />
                                    </a>}
                                    {this.props?.miningInfo?.twitter && <a style={socialStyle} className="" href={this.props.miningInfo.twitter} target="_blank" rel="noreferrer">
                                        <i className='fab fa-twitter' />
                                    </a>}
                                    {this.props?.miningInfo?.reddit && <a style={socialStyle} className="" href={this.props.miningInfo.reddit} target="_blank" rel="noreferrer">
                                        <i className='fab fa-reddit' />
                                    </a>}
                                    {this.props?.miningInfo?.facebook && <a style={socialStyle} className="" href={this.props.miningInfo.facebook} target="_blank" rel="noreferrer">
                                        <i className='fab fa-facebook' />
                                    </a>}
                                    {this.props?.miningInfo?.github && <a style={socialStyle} className="" href={this.props.miningInfo.github} target="_blank" rel="noreferrer">
                                        <i className='fab fa-github' />
                                    </a>}
                                    {this.props?.miningInfo?.discord && <a style={socialStyle} className="" href={this.props.miningInfo.discord} target="_blank" rel="noreferrer">
                                        <i className='fab fa-discord' />
                                    </a>}
                                    {this.props?.miningInfo?.instagram && <a style={socialStyle} className="" href={this.props.miningInfo.instagram} target="_blank" rel="noreferrer">
                                        <i className='fab fa-instagram' />
                                    </a>}


                                </div>
                                <ul className="list-inline">
                                    {/* {this.state.widgetData.map((item, idx) => {
                                        return (
                                            <li key={`fwd_${idx}`} className="list-inline-item"><a href={item.link}>{item.text}</a></li>
                                        );
                                    })} */}
                                </ul>
                                {/* Copyright Area */}
                                {/* <div className="copyright-area py-4">©2023, All Rights Reserved By <a href='https://fatsale.finance' target="_blank" rel="noreferrer">Fatsale</a></div> */}
                            </div>
                            {/* Scroll To Top */}
                            <div id="scroll-to-top" className="scroll-to-top">
                                <a href="#header" className="smooth-anchor">
                                    <i className="fa-solid fa-arrow-up" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

// export default Footer;


function mapStateToProps(state) {
    const miningInfo = state.miningInfo;
    return {
        miningInfo,
        // text
    };
}

// export default ProjectSingle;
export default connect(mapStateToProps)(Footer)
